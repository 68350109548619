.growth_chart_main_container {width: 100%;height: 0;padding-top: 75%; /* svg_height / svg_widht * container_width: 600/800*100  */position: relative;}
.axis path,.axis line {fill: none;stroke: black;shape-rendering: crispEdges;}
.line {fill: none;stroke: gray;stroke-width: 1.5px;}
text {font-family: sans-serif;font-size: 11px;}
text.label {fill:black;/*stroke-width:2px;*/font-size:8px;}
.growth_chart_main_container>svg.growth_chart_main_svg {position: absolute;top: 0;left: 0;min-height:100%; }
            
.backgroundRect {fill: rgba(255,255,255,1);stroke: rgba(20,20,20, .8);}
.tooltipTextBackground {fill: rgba(237,126,30,0.5);/*stroke: rgba(20,20,20, .8);*/}
.axis.y.left>.tick>line,.axis.x.bottom>.tick>line {fill:none;stroke:none;}
.axis.x>.tick>line.main,.axis.y.left>.tick>line.main{fill:none!important;stroke:#fff!important;opacity:0.2!important;stroke-width:2px!important;}

.SD0,.P50 {stroke: #00FF00 !important; /*green*/}
.SD0P5neg,.SD0P5,.P75,.P25 {stroke: #8FFF00 !important; /*light green*/}
.SD1neg,.SD1,.P85,.P15 {stroke: #FFFF00 !important; /*yellow*/}
.P90,.P10 {stroke: #FFAF00 !important;}
.SD1P5neg,.SD1P5,.P95,.P5 {stroke: #FF6F00 !important; /*Orange*/}
.SD2neg,.SD2,.P97,.P3 {stroke: #FF0000 !important; /*red*/}
.P99,.P1 {stroke: dimgray !important; /*White*/}
.SD3neg, .SD3,.P999,.P01 {stroke: #000000 !important; /*Black*/}    

.areamale {fill: steelblue;/*opacity: 0.1;*/}
.areafemale {fill: lightpink;/*opacity: 0.1;*/}
.pLine {fill: none;stroke: black;stroke-width: 1.5px;}
.dot {fill: black;stroke: black;stroke-width: 1.5px;}
.dotSelected {fill: rgb(237,126,30);stroke: rgb(237,126,30);stroke-width: 1.5px;z-index:1000!important;}
.rect-to-axis {fill: rgba(0,0,0,0);stroke: rgba(0,0,0, 1);stroke-dasharray:6;}
           