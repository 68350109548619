@media all and (min-width: 480px) {
    /* .FastVisit {
      padding: 0 0;
    } */
  
    /*
    .FastVisit .form {
      margin: 0 auto;
      max-width: 320px;
    }
    */
  }

  
  /* .nav-item.nav-link.active{
    background-color: red!important;
  } */

  /* .nopadding
  {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
  } */

  .nopadding-left{ padding-left: 0!important;}
  .nopadding-rigth{ padding-right: 0!important;}
  .nopadding-top{ padding-top: 0!important;}
  .nopadding-bottom{ padding-bottom: 0!important;}