.scrollableChartList{
    max-height: calc(70vh) !important;
    overflow-y: auto !important;
    width:100%!important;
  }

  div.noPadding {
    padding-left: 0!important;
    padding-right: 0!important;
}

div.noMargin {
    margin-left: 0!important;
    margin-right: 0!important;
}

.DrowdownToggle{
  width:100%!important;
}